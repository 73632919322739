<template>
    <div class="wp">
        <div class="wp1">
            <p class="col1582 font16 fontw6 padb14">知识库文档</p>
            <el-autocomplete
                class="inline-input w540"
                v-model="state1"
                size="large"
                :trigger-on-focus="false"
                @keyup.enter="onSubmit" 
                :fetch-suggestions="querySearch"
                placeholder="请输入文档所属产品、模块或者子模块名称"
                @select="handleSelect"
                >
                <template #suffix>
                    <div @click="onSubmit" class="flex flexa padr10 point" style="height:100%" >
                        <el-icon class="el-icon-search"></el-icon>
                    </div>
                </template>
            </el-autocomplete>
            <!-- <div class="flex padt10 ">
                <p class="cola1 padr20 nowrap">搜索历史</p>
                <div class="history">
                    <span v-for="(item,index) in 3" :key="index" class="">CVM数据库</span>
                </div>
            </div> -->
        </div>

        <div class="bgf padd30 mart30 flex wp2">
            <ul class="ul1">
                <li @click="cgeCate(item.id)" :class="cateid== item.id ? 'colzhuti':'' " v-for="(item,index) in cateList" :key="index">{{item.title}}</li>
            </ul>
            
            <div class="right">
                <div v-for="(item,index) in fenList" :key="index" class="padb40">
                    <p class="col24 font14 padb20">{{item.title}}</p>
                    <el-row class="ul2" v-if="item.threeCategoryList.length>0" >
                        <el-col v-for="(ite,idx) in item.threeCategoryList" :key="idx" :span="6" >
                            <div @click="$router.push('/search/cate/'+ite.id )" class="txt1">{{ite.title}}</div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { categoryList, twoThreeCategoryList, recommended } from "@/utils/api1.js"

export default {
    data(){
        return{
            state1: '',
            cateList: [],
            cateid: '',
            fenList: [],
        }
    },
    mounted(){
        var that = this ;
        categoryList().then(res=>{
            console.log(res)
            if(res){
                that.cateList = res;
                that.cateid = res[0].id;
                that.getfenleiList();
            }
        });

        
    },
    methods:{
        onSubmit(){
            console.log('回车')
            console.log(this.state1);
            this.$router.push({
                path: '/search/sch/l',
                query: {
                    value: this.state1
                }
            })
        },

        // 获取二三级分类
        getfenleiList(){
            twoThreeCategoryList(this.cateid).then(res=>{
                console.log(res);
                if(res){
                    this.fenList = res;
                }
            })
        },

        // 切换一级分类
        cgeCate(id){
            this.cateid = id;
            this.getfenleiList();
        },
        toSearch(){
            this.$router.push('/search/txt')
        },
        
        handleSelect(val){
            console.log(val);
            this.$router.push({
                path: '/search/sch/l',
                query: val
            })
            // if(val.type=='4'){
            //     // 知识文档
            //     this.$router.push('/knowledgeInfo/'+val.id)

            // }else{
            //     this.$router.push({
            //         path: '/search/sch/l',
            //         query: val
            //     })
            // }
        },
        querySearch( txt ,cb ){
            console.log(txt);
            if(txt){
                recommended({searchContent:txt, isShow: 1}).then(res=>{
                    console.log(res);
                    if(res){
                        let result = res.map((terminal) => {
                            return {
                                value: terminal.categoryTitle,
                                id: terminal.id,
                                type: terminal.type
                            };
                        });
                        cb(result)
                    }
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.wp{
    margin-left: -20px;
    margin-right: -20px;
    padding: 30px 20px 0;
    // background: url(../../assets/imgs/li.jpg) no-repeat left top;
    height: calc(100vh - 100px);
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
.wp2{
    height: 100%;
    box-sizing: border-box;
}
.history{
    >span{
        display: inline-block;
        padding: 0 16px 8px 0;
        color: #444;
        white-space: nowrap;
        cursor: pointer;
    }
    span:hover{
        color: #FF6A00;
    }
}
.ul1{
    background: #F1F1F1;
    padding: 30px 24px 0;
    box-sizing: border-box;
    width: 260px;
    min-width: auto;
    // font-size: 16px;
    font-family: MicrosoftYaHeiSemibold;
    color: #242424;
    font-weight: 550;
    white-space: nowrap;
    li{
        padding-bottom: 40px;
        cursor: pointer;
    }
    li:hover{
        color: #FF6A00;
    }
}
.ul2{
    border: 1px dashed #CBCBCB;
    text-align: center;
    padding-bottom: 26px;
    font-size: 14px;
    color: #41464A;
    .txt1{
        padding-top: 26px;
        cursor: pointer;
    }
    .txt1:hover{
        color: #FF6A00;
    }
}
.right{
    padding-left: 60px;
    width: 100%;
    // overflow-y: scroll;
}
</style>